/* eslint no-console:0 */

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import 'bootstrap/dist/css/bootstrap'
import 'bootstrap/dist/js/bootstrap.bundle'
import Cookies from 'js-cookie'

require("jquery")

Rails.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', function(){
  $('#toggle_lf').on('change', function(){
    let lf = Cookies.get('lf')
    let new_lf = (lf === '1') ? '0' : '1'
    let href = new URL(window.location.href);
    href.searchParams.set('lf', new_lf);
    window.location.href = href
  })
}, false);

